<template>
  <editor
    v-model="editorContent"
    api-key="6iyf5pv4clnbkp8bablx3cghk9mssk1fbsf39ccdh6gb72b5"
    initial-value=""
    :init="mceInit"
    @change="handlerFunction"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  components: {
    Editor
  },
  props: {
    content: { type: String, default: '' },
    objectType: { type: String, default: '' },
    options: { type: Object, default: () => {} },
    parentObject: { type: Object, default: () => {} }
  },
  emits: ['editor-content-changed'],
  data() {
    return {
      editorContent: this.content, 
      mceInit: {
        language: 'en',
        height: this.options && this.options.height ? this.options.height : 500,
        inline: this.options && this.options.inline ? this.options.inline : false,
        menubar: false,
        entity_encoding: 'raw',
        entities: '160,nbsp,38,amp,60,lt,62,gt',
        cleanup: true,
        paste_auto_cleanup_on_paste: true,
        paste_postprocess(pl, o) {
          // eslint-disable-next-line no-param-reassign
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/gi, ' ')
        },
        plugins: [
          'advlist autolink lists link image charmap searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
        ],
        toolbar:
          'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image | table | code | help',
        images_upload_url: 'postAcceptor.php',
        images_upload_handler: this.fileUpload,
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg',
        content_css: false, // prevent loading from cdn - scss in assets .fox
        skin: false,  // prevent loading from cdn - content_style
        content_style: `body{font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;line-height:1.4;margin:1rem}table{border-collapse:collapse}table:not([cellpadding]) td,table:not([cellpadding]) th{padding:.4rem}table[border]:not([border="0"]):not([style*=border-width]) td,table[border]:not([border="0"]):not([style*=border-width]) th{border-width:1px}table[border]:not([border="0"]):not([style*=border-style]) td,table[border]:not([border="0"]):not([style*=border-style]) th{border-style:solid}table[border]:not([border="0"]):not([style*=border-color]) td,table[border]:not([border="0"]):not([style*=border-color]) th{border-color:#ccc}figure{display:table;margin:1rem auto}figure figcaption{color:#999;display:block;margin-top:.25rem;text-align:center}hr{border-color:#ccc;border-style:solid;border-width:1px 0 0 0}code{background-color:#e8e8e8;border-radius:3px;padding:.1rem .2rem}.mce-content-body:not([dir=rtl]) blockquote{border-left:2px solid #ccc;margin-left:1.5rem;padding-left:1rem}.mce-content-body[dir=rtl] blockquote{border-right:2px solid #ccc;margin-right:1.5rem;padding-right:1rem}`
      },
    }
  },
  watch: {
    content(content) {
      this.editorContent = content
    }
  },
  methods: {
    fileUpload(blobInfo, success, failure) {
      const formdata = new FormData()
      formdata.append('file', blobInfo.blob())
      formdata.append('tags', ['tinymce'])
      
      this.$Files.createResource({ body: formdata })
        .then(response => {
          if (response.status === 201) {
            success(`${response.data.url}`)
          } else {
            failure(this.$t('file.failure'))
          }
        })
        .catch(() => {
          failure(this.$t('file.failure'))
        })
    },
    handlerFunction() {
      if(this.parentObject) {
        this.$emit('editorContentChanged', { parent: this.parentObject, content: this.editorContent })
      } else {
        this.$emit('editorContentChanged', this.editorContent)
      }
    },
  },
}
</script>
