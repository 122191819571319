<template>
  <b-form @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <b-form-group
              :class="{ error: v$.configuration.name.$errors.length }"
              :label="$t('configuration.name')"
            >
              <b-form-input v-model="configuration.name" />
              <div
                class="input-errors"
                v-for="error of v$.configuration.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group :label="$t('configuration.description')">
              <tinymce
                :content="configuration.description"
                @editorContentChanged="updateContent"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group :label="$t('configuration.bold')">
              <b-form-checkbox v-model="configuration.bold" switch />
            </b-form-group>

            <b-form-group :label="$t('configuration.quantityAble')">
              <b-form-checkbox v-model="configuration.quantityAble" switch />
            </b-form-group>

            <b-form-group
              :class="{ error: v$.configuration.price.$errors.length }"
              :label="$t('configuration.price')"
            >
              <b-form-input
                type="text"
                v-model="configuration.price"
              />
              <div
                class="input-errors"
                v-for="error of v$.configuration.price.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <image-upload
              :preview-image="configuration.fileUrl"
              :label="$t('forms.featuredImage')"
              @fileUploaded="ilustrationImageChange"
              v-if="configuration['@id']"
              :resource="configuration['@id']"
              file-attribute="FILE"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {
    tinymce,
    ImageUpload,
  },
  props: {
    configurationObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      configuration: {
        name: { required: this.$translateError('required') },
        price: { required: this.$translateError('required'), decimal: this.$translateError('decimal') },
      },
    };
  },
  created() {
    this.getEnumList("configuration_type", this.types);
  },
  data() {
    return {
      configuration: this.configurationObject,
      types: [],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.configuration.file = data["@id"];
      this.configuration.fileURL = data.url;
    },
    updateContent(content) {
      this.configuration.description = content;
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.configuration.name,
          price: this.configuration.price,
          description: this.configuration.description,
          quantityAble: this.configuration.quantityAble,
          bold: this.configuration.bold,
          file: this.configuration.file,
        };
        if (this.action) {
          this.action === "add"
            ? this.create(
                this.$Configurations,
                body,
                this.$t("configuration.created"),
                null,
                this.success,
                this.error
              )
            : this.update(
                this.$Configurations,
                this.configuration.id,
                body,
                this.$t("configuration.updated"),
                null,
                null,
                this.error
              );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/configurations`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
